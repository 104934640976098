<template>
  <b-card
    no-body
    :class="{ open: visible }"
  >
    <b-card-header
      ref="myCollapsible"
      :class="{ collapsed: visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="collapseOpen(!visible, $event)"
    >
      <slot name="header">
        <!-- <span class="">{{ avatar }}</span> -->
        <span class="lead collapse-title">
          <b-media class="media">
            <template #aside>
              <b-avatar
                v-if="isAvatar"
                size="32"
                :src="src"
              />
            </template>
            <div>
              <b-link>
                <span class="d-block font-weight-bolder text-nowrap">
                  {{ title }}
                </span>
              </b-link>
            </div>
          </b-media>
        </span>
        <div
          v-if="userbannerads"
          class="d-flex"
        >
          <div>
            <vs-switch
              v-model="switchs"
              circle
              class="switches"
              style="background-color: #7FF0B2"
              @click="disabledSwitchFunc($event)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Stop</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Play</span>
            </vs-switch>
          </div>
          <div
            class="ml-1 time"
            @click="timeDiv($event)"
          >
            <b-img
              size="16px"
              :src="require('@/assets/images/simiicons/wall-clock.svg')"
            />
            {{ time }}
          </div>
        </div>
        <div
          v-if="switches "
          class="d-flex"
        >
          <div>
            <vs-switch
              v-model="switchs"
              circle
              class="switches"
              :style="`background-color: ${!switchs ? '#FF0000' : '#00E265'} `"
              @click="switchFunc($event)"
            >
              <span
                slot="on"
                style="font-weight: bold"
              >Stop</span>
              <span
                slot="off"
                style="font-weight: bold"
              >Play</span>
            </vs-switch>
          </div>
          <div
            class="ml-1 time"
            @click="timeDiv($event)"
          >
            <b-img
              size="16px"
              :src="require('@/assets/images/simiicons/wall-clock.svg')"
            />
            {{ time }}
          </div>
        </div>
        <div>
          <span v-if="bannerads">
            <feather-icon
              :id="id"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              style="margin: 8px"
              @click="review($event)"
            />
          </span>
          <span v-if="editicon">
            <feather-icon
              :id="id"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-toggle.edit-profile
              icon="Edit2Icon"
              class="cursor-pointer"
              size="16"
              style="margin: 8px"
              @click="editFunc($event)"
            />
            <EditProfile
              :id="id"
              ref="edit-profile-modal"
              :src="src"
              :title="title"
              :updateprofiles="edit"
            />
          </span>
          <feather-icon
            :id="id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :icon="icons"
            class="cursor-pointer"
            style=" padding: 6px"
            size="28"
            @click="deleteFunc($event)"
          />
          <DeleteProfile
            :id="id"
            ref="delete-profile-modal"
            :updateprofiles="edit"
          />
        </div>
      </slot>
    </b-card-header>
    <div v-if="bannerads && visible">
      <div class="device">
        Devices
      </div>
      <b-row class="mx-0">
        <b-col
          v-for="(data,index) in swiperData"
          :key="index"
          class="flex-grow-0 align-items-left my-1 mx-0"
        >

          <b-media vertical-align="center">
            <template #aside>
              <b-img
                size="32"
                :src="require('@/assets/images/simiicons/device.svg')"
              />
            </template>
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.text }}
            </span>
            <small class="text-muted">{{ data.size }}</small>
          </b-media>
        </b-col>
      </b-row>
    </div>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body style="padding: 0px !important">
        <slot />
      </b-card-body>
    </b-collapse>
    <div v-if="showReview">
      <LightBox
        ref="lightbox"
        :media="media"
        :show-thumbs="play"
        :length-to-load-more="media.length"
        @onLastIndex="onLastIndex"
        @onLoad="onLoad"
        @onClosed="onClosedEvent"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BMedia,
  BAvatar,
  BLink,
  BImg,
  BRow,
  BCol,
  VBToggle,

} from 'bootstrap-vue'
import { required } from '@validations'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vuesax from 'vuesax'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import '@/assets/images/simiicons/edit.svg'
import { mapState } from 'vuex'
import LightBox from 'vue-it-bigger'
import DeleteProfile from '../profiles/DeleteProfile.vue'
import EditProfile from '../profiles/EditProfile.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    DeleteProfile,
    EditProfile,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BMedia,
    BAvatar,
    BLink,
    BImg,
    BRow,
    BCol,
    LightBox,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['isVisible', 'title', 'bannerads', 'icons', 'icon', 'isAvatar', 'switches', 'src', 'time', 'id', 'edit', 'editicon', 'userbannerads'],
  data() {
    return {
      disabledButton: false,
      active: false,
      showReview: false,
      media: [
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/01-zell-am-see-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/01-zell-am-see.jpg',
          caption: 'Zell am See, Austria',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/02-balea-lake-chalet-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/02-balea-lake-chalet.jpg',
          caption: 'Balea Lake Chalet, Romania',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/03-maybug-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/03-maybug.jpg',
          caption: 'Maybug',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/04-rc-car-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/04-rc-car.jpg',
          caption: 'An RC Car',
        },
        {
          thumb: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRx1TQ3PfnhWmLUWcxrGj3atDCZVTVBKetMOWVVQ4ipIStW03pY',
          sources: [
            {
              src: 'https://www.w3schools.com/html/mov_bbb.mp4',
              type: 'video/mp4',
            },
          ],
          type: 'video',
          caption: 'Monsters Inc.',
          width: 800,
          height: 600,
          autoplay: true,
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/05-tourists-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/05-tourists.jpg',
          caption: 'Two tourists on a mountain',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/06-dog-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/06-dog.jpg',
          caption: 'Dog',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/07-beer-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/07-beer.jpg',
          caption: 'Mmm, Beer! - This should be a short description, as it is after all, beer, but let\'s make it long so that we can test how the footer looks',
        },
        {
          thumb: 'https://vue-it-bigger.rusiczki.net/08-roosters-crest-thumbnail.jpg',
          src: 'https://vue-it-bigger.rusiczki.net/08-roosters-crest.jpg',
          caption: 'The Rooster\'s Crest',
        },
      ],
      swiperData: [
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
        {
          /* eslint-disable global-require */
          icon: "require('@/assets/images/simiicons/device.svg')",
          text: 'Row-3-Dievel',
          size: '43-Inches',
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        // centeredSlides: true,
        spaceBetween: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      switchs: true,
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      // validation
      required,
    }
  },
  computed: {
    ...mapState(['allProfiles']),
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    collapseOpen(val, e) {
      e.preventDefault()
      e.stopPropagation()
      this.visible = val
    },
    collapseClose() {
      if (this.openOnHover) {
        this.visible = false
      }
    },
    deleteFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs['delete-profile-modal'].show()
    },
    editFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs['edit-profile-modal'].show()
    },
    review(e) {
      e.preventDefault()
      e.stopPropagation()
      this.showReview = !this.showReview
    },
    switchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.switchs = !this.switchs
    },
    timeDiv(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    disabledSwitchFunc(e) {
      e.preventDefault()
      e.stopPropagation()
      this.dis = true
      this.$refs.myCollapsible.close()
    },
  },
}
</script>
<style scoped>
.switches {
  border-radius: 25px;
}
.device{
  font-size: 14px;
  letter-spacing: 0px;
  color: #414141;
  font-weight: 600;
  font-family: "Montserrat";
  padding-left: 15px;
 }
 .time{
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  color: #414141;
  font-weight: 500;
   margin-top: 4px;
   font-family: "Montserrat";
 }
</style>
